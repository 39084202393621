
@import '../../../assets/styles/common.scss';

/* 全局样式 */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* 头部样式 */
.header {
    width: 100%;
    position: relative;
    padding-top: fluid-width(0);
    padding-bottom: fluid-width(20);
}

.backButton {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 24px;
    color: #333;
    z-index: 10;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: fluid-width(40);
    padding-bottom: fluid-width(10);
}

.titleImg {
    width: fluid-width(236);
    height: fluid-width(135);
}

.badge {
    position: absolute;
    right: fluid-width(-1);
    top: fluid-width(10);
    width:fluid-width(68);
    height:fluid-width(106);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.badge span {
    display: block;
    padding-top: fluid-width(37);
    padding-right: fluid-width(9);
    font-size: fluid-width(22);
    font-weight: 600;
    color: #8EC2FB;
    text-align: center;
    transform: rotate(16.46deg); /* 确保文本也旋转 */
    line-height: 1;
}

.tickerContainer {
    top: fluid-width(30);
    width: 100%;
    height: fluid-width(30); /* 单个轨道高度 */
    overflow: hidden;
    position: relative;
    background-color: #FF4D6A;
}

.tickerItem {
    display: flex;
    align-items: center; 
    color: white;
    border-radius: fluid-width(20);
    font-size: fluid-width(12);
    width: max-content; /* 让宽度适应内容 */
    padding: 0 fluid-width(10);
    position: absolute;
    left: 100%; /* 从容器右侧开始 */
    top: 50%; /* 垂直居中 */
    transform: translateY(-50%);
    white-space: nowrap; /* 防止文本换行 */
    animation: tickerScroll 15s linear; /* 设置基础动画 */
    animation-fill-mode: forwards; /* 动画结束后保持结束状态 */
}

/* 定义滚动动画 */
@keyframes tickerScroll {
    from {
        left: 100%; /* 从右侧开始 */
    }
    to {
        left: -100%; /* 滚动到左侧外部 */
    }
}

.tickerItem img {
    width: fluid-width(20);
    height: fluid-width(20);
    border-radius: 50%;
    margin-right: fluid-width(8);
}






/* 抽奖盒子样式 */
.prizeBox {
    position: absolute;
    top: fluid-width(220);
    left: fluid-width(-0);
    width: 100%;
    height:  fluid-width(509);
    flex-direction: column;
    background-size: contain; /* 改为contain确保整个图片都在容器内 */
    background-repeat: no-repeat;
}

.prizeGrid {
    position: relative;
    align-items: center; 
    width:  fluid-width(412 * 0.85);
    padding-bottom:  fluid-width(509 * 0.60);
    background-color: transparent;
    border-radius: fluid-width(15);
    margin-top: fluid-width(35);
    margin-left: fluid-width(35);
}

.prizeItem {
    position: absolute;
    background-color: #211F1C;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible; /* 允许内容溢出 */

    /* 添加过渡效果基础值，即使在未高亮状态也有相同的过渡属性 */
    transition: transform 0.1s ease;
    transform: scale(1.0);

    /* 禁止文本选择 */
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    
    /* 禁止触摸操作 */
    -webkit-touch-callout: none;
    touch-action: manipulation;
}

.prizeItem img {
    position: absolute;
    width: auto;
    height: auto;
    max-width: none;
    max-height: none;
    /* 可以根据需要添加缩放 */
    transform: scale(1.0); 

    /* 禁止拖拽图片 */
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    
    /* 禁止图片上下文菜单 */
    pointer-events: none;
}

/* 相机（左上角） */
.prizeItem:nth-child(1) {
    top: fluid-width(5);
    left: fluid-width(-5);
    width: fluid-width(123);
    height: fluid-width(105);
}

.prizeItem:nth-child(1) img {
    top:  fluid-width(-3);
    position: absolute;
    width: 100%;  
    height: auto;
}

/* 谢谢参与（中左） */
.prizeItem:nth-child(2) {
    top:  fluid-width(5);
    left: fluid-width(125);
    width: fluid-width(65);
    height: fluid-width(78);
}

.prizeItem:nth-child(2) img {
    position: absolute;
    width: 85%;  
    height: auto;
}

/* 红包 ¥2.33（右2） */
.prizeItem:nth-child(3) {
    top:  fluid-width(5);
    left: fluid-width(196);
    width: fluid-width(65);
    height: fluid-width(78);
}

.prizeItem:nth-child(3) img {
    position: absolute;
    width: 90%;  
    height: auto;
}

/* 红包 ¥6.66（右1） */
.prizeItem:nth-child(4) {
    top:  fluid-width(5);
    left: fluid-width(267);
    width: fluid-width(65);
    height: fluid-width(78);
}

.prizeItem:nth-child(4) img {
    position: absolute;
    width: 90%;  
    height: auto;
}

/* 红包 ¥9.99（右中） */
.prizeItem:nth-child(10) {
    top:  fluid-width(93);
    left: fluid-width(267);
    width: fluid-width(65);
    height: fluid-width(78);
}

.prizeItem:nth-child(10) img {
    position: absolute;
    width: 90%;  
    height: auto;
}

/* 中央提示文字 */
.prizeMessage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.prizeTipsImg {
    width: fluid-width(147);
    height: fluid-width(76);
}

.prizeTipsCount {
    position: absolute;
    top: fluid-width(-2);
    left: fluid-width(79);
    width: fluid-width(20);
    height: fluid-width(38);
}

/* （中下）红包 ¥18.8 */
.prizeItem:nth-child(7) {
    bottom: fluid-width(5);
    left: fluid-width(142);
    width: fluid-width(65);
    height: fluid-width(78);
}

.prizeItem:nth-child(7) img {
    position: absolute;
    width: 90%;  
    height: auto;
}

/* 右下行李箱 */
.prizeItem:nth-child(8) {
    bottom: fluid-width(5);
    right: fluid-width(20);
    width: fluid-width(121);
    height: fluid-width(110);
}
.prizeItem:nth-child(8) img {
    top: fluid-width(-16);
    position: absolute;
    width: 90%;  
    height: auto;
}

/* 左下优惠卷 */
.prizeItem:nth-child(9) {
    bottom: fluid-width(5);
    left: fluid-width(0);
    width: fluid-width(136);
    height: fluid-width(83);
}

.prizeItem:nth-child(9) img {
    position: absolute;
    width: 98%;  
    height: auto;
    bottom: fluid-width(-5);
}

/* 左 谢谢参与 */
.prizeItem:nth-child(5) {
    top:  fluid-width(125);
    left: fluid-width(0);
    width: fluid-width(65);
    height: fluid-width(78);
}

.prizeItem:nth-child(5) img {
    position: absolute;
    width: 85%;  
    height: auto;
}


/* 使用背景图片的高亮样式 */
.activeHighlight {
  /* 减少过渡时间，使切换更快 */
  transition: transform 0.1s ease !important;
  z-index: 0.1 !important;
  
  /* 使用will-change提示浏览器优化 */
  will-change: transform;
  
  /* 使用背景图片 */
  background-image: url('./images/prize_highlightBg.png') !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;

  }
  
  /* 如果您想使用不同类型的背景效果 */
  .activeHighlight::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
    // animation: rotate 1s infinite linear;
  }


/* 可以添加一个中奖项的脉冲动画，使其更醒目 */
// @keyframes winPulse {
//     0% { transform: scale(1.05); box-shadow: 0 0 10px 2px rgba(255, 215, 0, 0.7); }
//     50% { transform: scale(1.1); box-shadow: 0 0 20px 5px rgba(255, 215, 0, 0.9); }
//     100% { transform: scale(1.05); box-shadow: 0 0 10px 2px rgba(255, 215, 0, 0.7); }
//   }
  
//   /* 为中奖项添加动画类 */
//   .winningItem {
//     animation: winPulse 1.5s infinite ease-in-out !important;
//   }

































/* 抽奖信息部分 */
.drawInfo {
    left: fluid-width(0);
    width: 100%;
    height:  fluid-width(509);
    flex-direction: column;
    background-size: contain; /* 改为contain确保整个图片都在容器内 */
    background-repeat: no-repeat;
}

.drawCount {
    position: relative; /* 添加绝对定位 */
    display: flex;
    justify-content: space-between; /* 改为两端对齐 */
    justify-content: center; /* 恢复为居中对齐 */
    align-items: center;
    top: fluid-width(47);
    left: 0;
    width: 100%;
    user-select: none; /* 禁止文本选择 */
    -webkit-user-select: none; /* Safari 浏览器兼容 */
    -moz-user-select: none; /* Firefox 浏览器兼容 */
    -ms-user-select: none; /* IE/Edge 浏览器兼容 */
}

.drawCount span {
    color: #FFFFFF;
    font-size: fluid-width(14);
    font-weight: semi-bold;
}

.extraCount {
    position: relative; /* 添加绝对定位 */
    display: flex;
    align-items: center;
    left: fluid-width(320);
    top: fluid-width(15); 
    width: fluid-width(75);
    height: fluid-width(70);
    background-color: transparent; /* 将背景设为透明 */
    border: none; /* 移除边框 */
    outline: none; /* 移除轮廓 */
    box-shadow: none; /* 移除阴影 */
}
  
.extraCount img {
    width: 100%; /* 图片宽度填满容器 */
    height: 100%; /* 图片高度填满容器 */
    object-fit: cover; /* 保持图片比例的同时填满容器 */

    /* 禁止选择文本 */
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-drag: none;
    
    /* 禁止长按弹出菜单 (主要针对移动设备) */
    -webkit-touch-callout: none;
    
    /* 调整触摸行为，但保持点击功能 */
    touch-action: manipulation;
    
    /* 确保cursor样式表明这是可点击的 */
    cursor: pointer;
}

.drawButton {
    position: relative; /* 使用绝对定位 */
    display: flex;
    top: fluid-width(-15); 
    left: 50%; 
    transform: translateX(-50%); 
    width: fluid-width(224);
    height: fluid-width(82);
    background-color: transparent; /* 将背景设为透明 */
    border: none; /* 移除边框 */
    outline: none; /* 移除轮廓 */
    box-shadow: none; /* 移除阴影 */

    /* 禁止选择文本 */
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-drag: none;
    
    /* 禁止长按弹出菜单 (主要针对移动设备) */
    -webkit-touch-callout: none;
    
    /* 调整触摸行为，但保持点击功能 */
    touch-action: manipulation;
    
    /* 确保cursor样式表明这是可点击的 */
    cursor: pointer;
}

.drawButton img {
    width: 100%; /* 图片宽度填满容器 */
    height:  auto;/* 图片高度填满容器 */
    object-fit: cover; /* 保持图片比例的同时填满容器 */

    /* 禁止选择文本 */
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-drag: none;
    
    /* 禁止长按弹出菜单 (主要针对移动设备) */
    -webkit-touch-callout: none;
    
    /* 调整触摸行为，但保持点击功能 */
    touch-action: manipulation;
    
    /* 确保cursor样式表明这是可点击的 */
    cursor: pointer;
}



/* 底部 */
.bottom {
    position: absolute;
    width: 100%;
    height: fluid-width(165);
    top: fluid-width(730);
    
    left:  fluid-width(11);
    // transform: translateX(-50%);
    // right: fluid-width(-26);
    display: flex;
    flex-direction: column;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: fluid-width(15);
    text-align: center;
    pointer-events: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

.bottomTipsTitle {
    display: block;
    padding-top: fluid-width(10);
    padding-right: fluid-width(45);
    color: #000;
    text-align: center;
    font-family: "PingFang SC";
    font-size: fluid-width(16);
    font-style: normal;
    font-weight: 600;
    line-height:  fluid-width(22); /* 137.5% */

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
}

.helperUsersOne {
    position: absolute;
    margin-top: fluid-width(43);
    margin-left: fluid-width(55);
    width:  fluid-width(70);
    height:  fluid-width(75);
    background-size: contain; /* 改为contain确保整个图片都在容器内 */
    background-repeat: no-repeat;
    transform: rotate(-10.528deg);
    pointer-events: none;
}

.helperUsersTwo {
    position: absolute;
    margin-top: fluid-width(45);
    margin-left: fluid-width(129);
    width:  fluid-width(70);
    height:  fluid-width(75);
    background-size: contain; /* 改为contain确保整个图片都在容器内 */
    background-repeat: no-repeat;
    transform: rotate(8.417deg);
    pointer-events: none;
}

.helperUsersThree {
    position: absolute;
    margin-top: fluid-width(38);
    margin-left: fluid-width(202);
    width:  fluid-width(70);
    height:  fluid-width(75);
    background-size: contain; /* 改为contain确保整个图片都在容器内 */
    background-repeat: no-repeat;
    transform: rotate(-10.528deg);
    pointer-events: none;
}


.helperUsersImg {
    position: absolute;
    top: fluid-width(4);
    left: fluid-width(5);
    width:  fluid-width(51);
    height:  fluid-width(55);
    object-fit: contain; 
}

.helperUsersName {
    position: absolute;
    bottom: fluid-width(0);
    left: fluid-width(2);
    width:  fluid-width(58);
    height:  fluid-width(15);
    color: #564230;
    text-align: center;
    font-family: "PingFang SC", sans-serif;
    font-size: fluid-width(7.5); /* 改为固定像素试试 */
    white-space: nowrap;       /* 不换行 */
    overflow: hidden;          /* 超出隐藏 */
    text-overflow: ellipsis;   /* 超出显示“...” */
}

.helperAddImg {
    position: absolute;
    top: 40%;
    left: 40%;
    transform: translate(-40%, -50%);
    width:  fluid-width(34);
    height:  fluid-width(34);
    object-fit: contain; 
    pointer-events: none;
}

.bottomClickArea {
    position: absolute;
    width: 100%;
    height: fluid-width(165);
    top: fluid-width(740);
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;

    /* 禁止选择文本 */
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-drag: none;
    
    /* 禁止长按弹出菜单 (主要针对移动设备) */
    -webkit-touch-callout: none;
    
    /* 调整触摸行为，但保持点击功能 */
    touch-action: manipulation;
    
    /* 确保cursor样式表明这是可点击的 */
    cursor: pointer;
}

.bottomRuleAndRecordContainer {
    position: relative;
    width: 100%;
    top: fluid-width(735);
    // height: fluid-width(15);

    /* 禁止选择文本 */
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-drag: none;
    
    /* 禁止长按弹出菜单 (主要针对移动设备) */
    -webkit-touch-callout: none;
    
    /* 调整触摸行为，但保持点击功能 */
    touch-action: manipulation;
    
    /* 确保cursor样式表明这是可点击的 */
    cursor: pointer;
}

.ruleButton{
    // top: fluid-width(160);
    left: fluid-width(125);
    background: none;
    border: none;
    color: #564230;
    font-family: "PingFang SC", sans-serif;
    font-size:fluid-width(14);
    font-weight: 600;
    cursor: pointer;
    position: relative;
    display: inline-block;
}

.ruleButton::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom:  fluid-width(-2);;  /* 控制下划线和文字的间距 */
    width: fluid-width(55);
    height: 1px;
    background-color: #564230;
  }

  .recordButton{
    // top: fluid-width(160);
    left: fluid-width(145);
    background: none;
    border: none;
    color: #564230;
    font-family: "PingFang SC", sans-serif;
    font-size:fluid-width(14);
    font-weight: 600;
    cursor: pointer;
    position: relative;
    display: inline-block;
}

.recordButton::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom:  fluid-width(-2);;  /* 控制下划线和文字的间距 */
    width: fluid-width(55);
    height: 1px;
    background-color: #564230;
  }


  






  /* 弹窗样式 */
.congratsPopup {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100; /* 确保比高亮效果高 */
}

.popupCard {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 101; /* 确保比高亮效果高 */
}

.popupCardStuff {
    position: relative;
    display: flex;
    left: fluid-width(0);
    width: fluid-width(390);
    height: fluid-width(680);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 101; /* 确保比高亮效果高 */
}


.closeButton {
    position: absolute;
    top: fluid-width(186);
    right: fluid-width(27);
    width: fluid-width(40);
    height: fluid-width(40);
    cursor: pointer;
    z-index: 102;
    
    img {
        width: 100%;
        height: 100%;
    }
}

.popupContent {
    display: none;
    border-radius: fluid-width(10);
    width: 100%;
    margin-top: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-size: contain;
}

.congratsImg {
    margin-top: fluid-width(30);
    color: #F8F0D8;
    width: fluid-width(223);
    height:  fluid-width(91);
}

.congratsTextOne {
    margin-top: fluid-width(30);
    color: #F7F3DE;
    font-family: "PingFang SC";
    font-size: fluid-width(20);
    font-weight: 600;
}

.congratsTextTwo {
    margin-top: fluid-width(0);
    color: #F7F3DE;
    font-family: "PingFang SC";
    font-size: fluid-width(20);
    font-weight: 600;
}

.congratsNum {
    color: #E7FF8F;
    font-size: fluid-width(20);
}


.congratsAvatar {
    display: inline-flex;     /* 水平排列 */
    align-items: center;      /* 垂直居中 */
    position: relative;       /* 可选：若需更灵活定位 */
    margin-top: fluid-width(25);
    margin-left: fluid-width(18);
}
  
.congratsAvatarItem{
    margin-left: fluid-width(-10);
    width: fluid-width(44);             /* 头像宽度 */
    height: fluid-width(44);            /* 头像高度 */
    border-radius: 50%;       /* 圆形 */
    object-fit: cover;        /* 图片裁剪模式 */
    border: fluid-width(2) solid #fff;   /* 白色边框，防止重叠时头像贴合 */
}

.congratsAvatarMore {
    margin-left: fluid-width(-18);      /* 负外边距，产生部分重叠效果 */
    width: fluid-width(48);             /* 头像宽度 */
    height: fluid-width(44);            /* 头像高度 */
    border-radius: 50%;       /* 圆形 */
    object-fit: cover;  
}

.wonPrizeLeft {
    position: absolute;   
    left: 0;
    width:  fluid-width(393);    
    height:  fluid-width(852);    
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 102;
    animation: playAndHide 2.2s linear forwards;
}

.wonPrizeRight {
    position: relative;    
    left: 100%;
    width:  fluid-width(393);    
    height:  fluid-width(852);    
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 102;
    animation: playAndHide 2.2s linear forwards;
}

@keyframes playAndHide {
    0% {
        opacity: 1;
    }
    99% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden; /* 完全隐藏元素 */
    }
}

.wonPrizeTitleImg {
    margin-top: fluid-width(-18);
    color: #F8F0D8;
    width: fluid-width(223);
    height:  fluid-width(91);
}

.wonPrizeImgContainer {
    margin-top: fluid-width(8);
    width: fluid-width(120);
    height: fluid-width(120);
    display: flex;
    justify-content: center;
    align-items: center;
}

.wonPrizeImg {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.wonPrizeText2 {
    position: absolute;
    top: fluid-width(504);
    color: #fff;
    font-family: "PingFang SC";
    font-size: fluid-width(20);
    font-weight:500;

    /* 限制一行，并超出显示省略号 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    /* 设置最大宽度，根据需要调整数值 */
    max-width: fluid-width(250);
}
.wonPrizeText3 {
    position: absolute;
    top: fluid-width(554);
    color: #fff;
    font-size: fluid-width(12);

    /* 限制一行，并超出显示省略号 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

     /* 设置最大宽度，根据需要调整数值 */
     max-width: fluid-width(250);
}

.sendPrizeTitleImg {
    margin-top: fluid-width(-10);
    margin-left: fluid-width(15);
    color: #F8F0D8;
    width: fluid-width(238);
    height:  fluid-width(112);
}

.sendPrizeText1 {
    position: absolute;
    top: fluid-width(380);
    margin-left: fluid-width(10);

    color: #F7F3DE;
    font-family: "PingFang SC";
    font-size: fluid-width(11.7);
    font-weight:500;

    /* 限制一行，并超出显示省略号 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    /* 设置最大宽度，根据需要调整数值 */
    max-width: fluid-width(240);
}

.sendPrizeText2 {
    position: absolute;
    top: fluid-width(425);
    color: #E7FF8F;
    font-size: fluid-width(20);
    font-weight:600;
    text-align: center;

    /* 限制一行，并超出显示省略号 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

     /* 设置最大宽度，根据需要调整数值 */
     max-width: fluid-width(250);
}

.sendPrizeText3 {
    position: absolute;
    top: fluid-width(450);
    color: #E7FF8F;
    font-size: fluid-width(20);
    font-weight:600;
    text-align: center;

    /* 限制一行，并超出显示省略号 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

     /* 设置最大宽度，根据需要调整数值 */
     max-width: fluid-width(250);
}

.fillDashedLine {
    position: absolute;
    top: fluid-width(410);
    left: fluid-width(85);
    border-bottom: fluid-width(1) dashed #FFF;
    margin: fluid-width(0) auto fluid-width(20);
    width: 60%;
}

.fillCardContainer {
    position: absolute;
    display: inline-flex; 
    align-items: center; 
    top: fluid-width(500);
    margin-left: fluid-width(15);
    gap: fluid-width(18);
}

.fillCard {
    width:  fluid-width(46);
    height:  fluid-width(64);
    background-color: #000;
    border: fluid-width(2) solid #E7FF8F;
    border-radius: fluid-width(4);

    color: #E7FF8F; /* 添加文本颜色，与边框颜色相同以提高可见度 */
    text-align: center; /* 文本居中 */
    font-size: fluid-width(24); /* 设置合适的字体大小 */
    font-weight: bold; /* 加粗文本使其更明显 */
    padding: 0; /* 移除内边距 */
    caret-color: #E7FF8F; /* 光标颜色 */
    outline: none; /* 移除默认的聚焦外框 */
}

.fillCard.filled {
    background-color: #E7FF8F;  /* 填充颜色 */
    color: #fff; /* 填充后的文本颜色 */
}

/* 改善聚焦时的样式 */
.fillCard:focus {
    box-shadow: 0 0 0 fluid-width(2) rgba(231, 255, 143, 0.5);
}

/* 没有中奖样式 */
/* 没有中奖样式 */
.sorryPopup {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100; /* 确保比高亮效果高 */
}

.sorryPrizeTitleImg {
    margin-top: fluid-width(304);
    color: #F8F0D8;
    width: fluid-width(232);
    height:  fluid-width(200);
}

.sorryButton {
    position: absolute;
    top: fluid-width(505);
    width: fluid-width(190);
    height: fluid-width(44);
    background-color: #EA562D;
    color: white;
    border: none;
    border-radius: fluid-width(20);
    padding: fluid-width(10) fluid-width(30);;
    font-size: fluid-width(16);
    font-weight: 600;
    cursor: pointer;
    z-index: 999;
}

.popupButton {
    position: absolute;
    top: fluid-width(595);
    width: fluid-width(190);
    height: fluid-width(44);
    background-color: #EA562D;
    color: white;
    border: none;
    border-radius: fluid-width(20);
    padding: fluid-width(10) fluid-width(30);;
    font-size: fluid-width(16);
    font-weight: 600;
    cursor: pointer;
    z-index: 999;
}

.popupStuffButton {
    position: absolute;
    left: 52%;
    transform: translateX(-50%);
    top: fluid-width(600);
    width: fluid-width(195);
    height: fluid-width(44);
    background-color: #EA562D;
    color: white;
    border: none;
    border-radius: fluid-width(22);
    padding: fluid-width(10) fluid-width(30);;
    font-size: fluid-width(15);
    cursor: pointer;
    z-index: 999;

    /* 防止文本换行 */
    white-space: nowrap;
    
    /* 内容溢出处理 */
    overflow: hidden;
    text-overflow: ellipsis;
    
    /* 确保文本居中 */
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* 文本自适应大小 (可选) */
    text-size-adjust: auto;
    -webkit-text-size-adjust: auto;
}

.inviteCloseButton {
    position: relative;
    // top: fluid-width(-10);
    // left: fluid-width(265);
    top: fluid-width(170);
    left: fluid-width(150);
    width: fluid-width(40);
    height: fluid-width(40);
    cursor: pointer;
    z-index: 102;
    
    img {
        width: 100%;
        height: 100%;
    }
}

.inviteBGContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10; /* 确保比高亮效果高 */
    background-color: rgba(0, 0, 0, 0.6);
}

.inviteBGImg {
    position: relative;
    display: flex;
    top: fluid-width(150);
    width: fluid-width(310);
    height: fluid-width(470);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 101; /* 确保比高亮效果高 */
}

.inviteTextOne {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    top: fluid-width(60);
    color: #fff;
    font-family: "PingFang SC";
    font-size: fluid-width(14);
    font-weight: 600;
}

.inviteTextTwo {
    position: absolute;
    display: flex;
    justify-content: center;
    top: fluid-width(85);
    width: 100%;
    color: #fff;
    font-family: "PingFang SC";
    font-size: fluid-width(14);
    font-weight: 600;
}

.inviteTextThree {
    position: absolute;
    display: flex;
    justify-content: center;
    top: fluid-width(125);
    width: 100%;
    color: #F7F3DE;
    font-family: "PingFang SC";
    font-size: fluid-width(17);
    font-weight: 600;
}

.inviteTextThreeSpecial {
    margin: 0 5px;
    margin-top: fluid-width(-8.5);
    color: #E7FF8F;
    font-family: "PingFang SC";
    font-size: fluid-width(28);
    // font-weight: 600;
}

.inviteTextFour {
    position: absolute;
    display: flex;
    justify-content: center;
    top: fluid-width(255);
    width: 100%;
    color: #F7F3DE;
    font-family: "PingFang SC";
    font-size: fluid-width(17);
    font-weight: 600;
}

.inviteTextFourSpecial {
    margin: 0 3px;
    margin-top: fluid-width(-3);
    color: #E7FF8F;
    font-family: "PingFang SC";
    font-size: fluid-width(20);
    // font-weight: 600;
}

.inviteTextFive {
    position: absolute;
    display: flex;
    justify-content: center;
    top: fluid-width(285);
    width: 100%;
    color: #F7F3DE;
    font-family: "PingFang SC";
    font-size: fluid-width(13);
    font-weight: 600;
}


.inviteStatusImg {
    position: absolute; /* 添加绝对定位 */
    display: flex;
    left: fluid-width(35);
    top: fluid-width(165);
    width: fluid-width(110);
    height: fluid-width(24);
}


.inviteFriendText {
    position: absolute;
    display: flex;
    justify-content: center;
    top: fluid-width(105);
    width: 100%;
    color: #F7F3DE;
    font-family: "PingFang SC";
    font-size: fluid-width(17);
    font-weight: 600;
}

.inviteFriendTextSpecial {
    margin: 0 5px;
    margin-top: fluid-width(-8.5);
    color: #E7FF8F;
    font-family: "PingFang SC";
    font-size: fluid-width(28);
    // font-weight: 600;
}

.inviteFriendStatusImg {
    position: absolute; /* 添加绝对定位 */
    display: flex;
    left: fluid-width(35);
    top: fluid-width(145);
    width: fluid-width(110);
    height: fluid-width(24);
}


.inviteWechatButton {
    position: absolute; /* 添加绝对定位 */
    display: flex;
    align-items: center; 
    left: 51%;
    transform: translate(-50%);
    top: fluid-width(345);
    width: fluid-width(245);
    height: fluid-width(55);
    background-color: transparent; /* 将背景设为透明 */
    border: none; /* 移除边框 */
    outline: none; /* 移除轮廓 */
    box-shadow: none; /* 移除阴影 */
}

.inviteWechatButton img {
    width: 100%;
    height: 100%;
    object-fit: contain;  /* 保持图片比例 */
}

.inviteQQButton {
    position: absolute; /* 添加绝对定位 */
    display: flex;
    align-items: center; 
    left: 50%;
    transform: translate(-50%);
    top: fluid-width(505);
    width: fluid-width(67);
    height: fluid-width(65);
    background-color: transparent; /* 将背景设为透明 */
    border: none; /* 移除边框 */
    outline: none; /* 移除轮廓 */
    box-shadow: none; /* 移除阴影 */
}

.inviteQQButton img {
    width: 100%;
    height: 100%;
    object-fit: contain;  /* 保持图片比例 */
}


.kflb_content_popup_content_white {

    position: relative;

    width: 89.58vw;
    height: 116.83vw;

    background-image: url('./images/kflb_bg_popup_white.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

/* 弹窗区域 */
.kflb_content_popup {

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.5);
}